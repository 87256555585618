import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { COLORS } from '../constants';
import { FONT_GOTHAM_MEDIUM } from '../styles/fonts';
import { findElementByText, scrollToBlock } from '../utils';
import arrowUp from '../images/arrow-up.svg';

export default ({
    headers,
}: {
    headers: { position: number; prefix: string; title: string }[];
}) => {
    const [isOpen, setIsOpen] = useState(true);

    return headers.length > 0 ? (
        <ArticleNav isOpen={isOpen}>
            <ArticleNavTitle isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
                Содержание
            </ArticleNavTitle>
            {headers.map((x, i) => (
                <ArticleNavItem
                    position={x.position}
                    key={i}
                    onClick={() =>
                        scrollToBlock(
                            findElementByText(`h${x.position}`, x.title),
                            {
                                offset: -80,
                            }
                        )
                    }
                >
                    <ArticleNavTitlePrefix>{x.prefix}.</ArticleNavTitlePrefix>
                    {x.title}
                </ArticleNavItem>
            ))}
        </ArticleNav>
    ) : null;
};

const ArticleNav = styled.div<{ isOpen: boolean }>`
    padding: 24px;
    background-color: ${COLORS.isabelline};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: ${({ isOpen }) => (isOpen ? 9999 : 62)}px;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 24px;
`;

const getStyles = (position) => {
    switch (position) {
        case 2: {
            return css`
                font-family: ${FONT_GOTHAM_MEDIUM};
                margin-top: 20px;
            `;
        }
        case 3: {
            return css`
                margin-left: 30px;
            `;
        }
        case 4: {
            return css`
                margin-left: 60px;
                font-style: italic;
            `;
        }
        case 5: {
            return css`
                margin-left: 70px;
                font-style: italic;
            `;
        }
        case 6: {
            return css`
                margin-left: 80px;
                font-style: italic;
            `;
        }
    }
};

const ArticleNavTitle = styled.div<{ isOpen: boolean }>`
    font-size: 20px;
    font-family: ${FONT_GOTHAM_MEDIUM};
    display: flex;
    cursor: pointer;

    &:after {
        content: '';
        background-image: url(${arrowUp});
        display: block;
        margin-left: 8px;
        margin-top: 2px;
        background-position: center;
        background-size: cover;
        width: 17px;
        height: 17px;
        transition: all 0.2s ease-in-out;

        transform: rotate(${({ isOpen }) => (isOpen ? 180 : 0)}deg);
    }
`;

const ArticleNavTitlePrefix = styled.div`
    margin-right: 12px;
    color: #907b7b;
    flex-shrink: 0;
`;

const ArticleNavItem = styled.div<{ position: number }>`
    font-size: 16px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;

    ${({ position }) => getStyles(position)}
`;
